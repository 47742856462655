import React, { useState, useEffect } from "react";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Box from "@mui/material/Box";
import HomeIcon from "@mui/icons-material/Home";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import DisplaySettingsIcon from "@mui/icons-material/DisplaySettings";
import PunchClockIcon from "@mui/icons-material/PunchClock";
import PeopleIcon from "@mui/icons-material/People";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
import ChatIcon from "@mui/icons-material/Chat";
import EditNoteIcon from "@mui/icons-material/EditNote";
import PersonIcon from "@mui/icons-material/Person";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";

const navBackgroundColor = "#111827";
const iconWidth = 60;

export default function NavigationSideBar(props) {
  const [isHovered, setIsHovered] = useState(false);
  const [trainingOpen, setTrainingOpen] = useState(false);
  const [configOpen, setConfigOpen] = useState(false);
  const [dailyPlanOpen, setDailyPlanOpen] = useState(false);
  const [logbookOpen, setLogbookOpen] = useState(false);

  useEffect(() => {
    const isTrainingTab = [
      "takeTrainingEmployee",
      "trainingOverview",
      "createTrainingContent",
    ].includes(props.currentTab);
    const isConfigTab = [
      "laborModel",
      "departmentRoleStationSettings",
      "locationsIntegrationsHoursSettings",
    ].includes(props.currentTab);
    const isDailyPlanTab = ["plan", "planConfiguration"].includes(
      props.currentTab
    );
    const isLogbookTab = ["logbook", "logbookConfiguration"].includes(
      props.currentTab
    );

    setTrainingOpen(isTrainingTab);
    setConfigOpen(isConfigTab);
    setDailyPlanOpen(isDailyPlanTab);
    setLogbookOpen(isLogbookTab);
  }, [props.currentTab]);

  const handleTrainingClick = () => {
    setTrainingOpen(!trainingOpen);
  };

  const handleConfigClick = () => {
    setConfigOpen(!configOpen);
  };

  const handleDailyPlanClick = () => {
    setDailyPlanOpen(!dailyPlanOpen);
  };

  const handleLogbookClick = () => {
    setLogbookOpen(!logbookOpen);
  };

  const drawerWidth = isHovered ? 270 : 60;
  const sidebarItems = [
    {
      icon: HomeIcon,
      title: "Home",
      tab: "home",
      roles: ["rightwork_internal", "company_admin", "company_manager"],
    },
    {
      icon: HomeIcon,
      title: "Home",
      tab: "employeeHome",
      roles: ["company_employee"],
    },
    {
      icon: ShowChartIcon,
      title: "Reporting",
      tab: "reporting",
      roles: ["rightwork_internal", "company_admin", "company_manager"],
    },
    {
      icon: AssignmentIndIcon,
      title: "Daily Plan",
      subtabs: [
        {
          title: "Task Assignment",
          tab: "plan",
          roles: ["rightwork_internal", "company_admin"],
          enabled: "dailyPlanEnabled",
        },
        {
          title: "Task Configuration",
          tab: "planConfiguration",
          roles: ["rightwork_internal", "company_admin"],
          enabled: "dailyPlanEnabled",
        },
      ],
      roles: ["rightwork_internal", "company_admin"],
      enabled: "dailyPlanEnabled",
    },
    {
      icon: AssignmentIndIcon,
      title: "Daily Plan",
      tab: "plan",
      roles: ["company_manager"],
      enabled: "dailyPlanEnabled",
    },
    {
      icon: PendingActionsIcon,
      title: "Approvals",
      tab: "actions",
      roles: ["rightwork_internal", "company_admin", "company_manager"],
    },
    {
      icon: EventAvailableIcon,
      title: "Published Schedule",
      tab: "publishedSchedule",
      roles: ["all"],
    },
    {
      icon: ChatIcon,
      title: "Chat",
      tab: "chat",
      roles: ["all"],
      enabled: "chatEnabled",
    },
    {
      icon: PeopleIcon,
      title: "Employees",
      tab: "employeesInformation",
      roles: ["rightwork_internal", "company_admin", "company_manager"],
    },
    {
      icon: MenuBookIcon,
      title: "Logbook",
      tab: "logbook",
      roles: ["company_manager"],
      enabled: "logbookEnabled",
    },
    {
      icon: MenuBookIcon,
      title: "Logbook",
      subtabs: [
        {
          title: "View Logbook",
          tab: "logbook",
          roles: ["rightwork_internal", "company_admin"],
          enabled: "logbookEnabled",
        },
        {
          title: "Configure Logbook",
          tab: "logbookConfiguration",
          roles: ["rightwork_internal", "company_admin"],
          enabled: "logbookEnabled",
        },
      ],
      roles: ["rightwork_internal", "company_admin"],
      enabled: "logbookEnabled",
    },

    {
      icon: WorkspacesIcon,
      title: "Department Settings",
      tab: "departmentRoleStationSettings",
      roles: ["company_manager"],
    },
    {
      icon: PunchClockIcon,
      title: "Time Entries",
      tab: "timeEntries",
      roles: ["rightwork_internal", "company_admin", "company_manager"],
      enabled: "timeEntriesEnabled",
    },
    {
      icon: PunchClockIcon,
      title: "Time Entries",
      tab: "timeEntriesEmployee",
      roles: ["company_employee"],
      enabled: "timeEntriesEnabled",
    },
    {
      icon: DisplaySettingsIcon,
      title: "Configurations",
      subtabs: [
        {
          title: "Labor Model",
          tab: "laborModel",
          roles: ["rightwork_internal", "company_admin"],
        },
        {
          title: "Department Settings",
          tab: "departmentRoleStationSettings",
          roles: ["rightwork_internal", "company_admin"],
        },
        {
          title: "Location Settings",
          tab: "locationsIntegrationsHoursSettings",
          roles: ["rightwork_internal", "company_admin"],
        },
      ],
      roles: ["rightwork_internal", "company_admin"],
    },
    {
      icon: LocalLibraryIcon,
      title: "My Training",
      tab: "takeTrainingEmployee",
      roles: ["company_employee"],
      enabled: "trainingEnabled",
    },
    {
      icon: LocalLibraryIcon,
      title: "Training",
      subtabs: [
        {
          title: "My Training",
          tab: "takeTrainingEmployee",
          roles: ["all"],
          enabled: "trainingEnabled",
        },
        {
          title: "Training Overview",
          tab: "trainingOverview",
          roles: ["rightwork_internal", "company_admin", "company_manager"],
          enabled: "trainingEnabled",
        },
        {
          title: "Create Training Content",
          tab: "createTrainingContent",
          roles: ["rightwork_internal", "company_admin", "company_manager"],
          enabled: "trainingEnabled",
        },
      ],
      roles: ["rightwork_internal", "company_admin", "company_manager"],
      enabled: "trainingEnabled",
    },
    {
      icon: PersonIcon,
      title: "User Profile",
      tab: "userProfileEmployee",
      roles: ["company_employee"],
    },
  ];

  const renderSidebarItem = (item) => {
    const isRoleAllowed =
      item.roles.includes(props.permissionRole) || item.roles.includes("all");
    const isFeatureEnabled = !item.enabled || props[item.enabled];

    if (isRoleAllowed && isFeatureEnabled) {
      if (item.subtabs) {
        const isSelected = item.subtabs.some(
          (subtab) => subtab.tab === props.currentTab
        );
        return (
          <React.Fragment key={item.title}>
            <ListItem
              button
              onClick={
                item.title === "Training"
                  ? handleTrainingClick
                  : item.title === "Daily Plan"
                  ? handleDailyPlanClick
                  : item.title === "Logbook"
                  ? handleLogbookClick
                  : handleConfigClick
              }
              sx={{
                height: "48px",
                backgroundColor:
                  isSelected && isHovered ? "#2f3340" : "transparent",
                "&:hover": {
                  backgroundColor: "#2f3340",
                },
                display: "flex",
                alignItems: "center",
                padding: 0,
              }}
            >
              <ListItemIcon
                sx={{
                  color: "#DCDCDC",
                  minWidth: iconWidth,
                  width: iconWidth,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <item.icon />
              </ListItemIcon>
              <ListItemText
                primary={item.title}
                sx={{
                  opacity: isHovered ? 1 : 0,
                  transition: "opacity 0.3s",
                  marginLeft: 1,
                  overflow: "hidden",
                  width: isHovered ? "250px" : "0px",
                  whiteSpace: "nowrap",
                }}
              />
              {isHovered && (
                <Box sx={{ marginLeft: "auto", marginRight: 1 }}>
                  {item.title === "Training" ? (
                    trainingOpen ? (
                      <ExpandLessIcon />
                    ) : (
                      <ExpandMoreIcon />
                    )
                  ) : item.title === "Daily Plan" ? (
                    dailyPlanOpen ? (
                      <ExpandLessIcon />
                    ) : (
                      <ExpandMoreIcon />
                    )
                  ) : item.title === "Logbook" ? (
                    logbookOpen ? (
                      <ExpandLessIcon />
                    ) : (
                      <ExpandMoreIcon />
                    )
                  ) : configOpen ? (
                    <ExpandLessIcon />
                  ) : (
                    <ExpandMoreIcon />
                  )}
                </Box>
              )}
            </ListItem>
            {isHovered && (
              <Collapse
                in={
                  item.title === "Training"
                    ? trainingOpen
                    : item.title === "Daily Plan"
                    ? dailyPlanOpen
                    : item.title === "Logbook"
                    ? logbookOpen
                    : configOpen
                }
                timeout="auto"
                unmountOnExit
              >
                <List component="div" disablePadding>
                  {item.subtabs.map((subtab) => {
                    const isSubtabAllowed =
                      subtab.roles.includes(props.permissionRole) ||
                      subtab.roles.includes("all");
                    const isSubtabEnabled =
                      !subtab.enabled || props[subtab.enabled];

                    if (isSubtabAllowed && isSubtabEnabled) {
                      return (
                        <ListItem
                          button
                          key={subtab.tab}
                          onClick={() => props.setCurrentTab(subtab.tab)}
                          sx={{
                            height: "40px",
                            backgroundColor:
                              props.currentTab === subtab.tab
                                ? "#3f4350"
                                : "transparent",
                            "&:hover": {
                              backgroundColor: "#3f4350",
                            },
                            paddingLeft: `${iconWidth}px`,
                          }}
                        >
                          <ListItemText
                            primary={subtab.title}
                            sx={{
                              opacity: isHovered ? 1 : 0,
                              transition: "opacity 0.3s",
                              marginLeft: 1,
                              overflow: "hidden",
                              width: isHovered ? "250px" : "0px",
                              whiteSpace: "nowrap",
                            }}
                          />
                        </ListItem>
                      );
                    }
                    return null;
                  })}
                </List>
              </Collapse>
            )}
          </React.Fragment>
        );
      } else {
        return (
          <ListItem
            button
            key={item.tab}
            onClick={() => props.setCurrentTab(item.tab)}
            sx={{
              height: "48px",
              backgroundColor:
                props.currentTab === item.tab ? "#2f3340" : "transparent",
              "&:hover": {
                backgroundColor: "#2f3340",
              },
              display: "flex",
              alignItems: "center",
              padding: 0,
            }}
          >
            <ListItemIcon
              sx={{
                color: "#DCDCDC",
                minWidth: iconWidth,
                width: iconWidth,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <item.icon />
            </ListItemIcon>
            <ListItemText
              primary={item.title}
              sx={{
                opacity: isHovered ? 1 : 0,
                transition: "opacity 0.3s",
                marginLeft: 1,
                overflow: "hidden",
                width: isHovered ? "250px" : "0px",
                whiteSpace: "nowrap",
              }}
            />
          </ListItem>
        );
      }
    }
    return null;
  };

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
          backgroundColor: navBackgroundColor,
          color: "#DCDCDC",
          transition: "width 0.3s ease-in-out",
          overflowX: "hidden",
          overflowY: "auto",
        },
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: 64,
          overflow: "hidden",
          flexShrink: 0,
        }}
      >
        {isHovered ? (
          <img
            src={process.env.PUBLIC_URL + "/images/logo_gray_new.png"}
            alt="RightWork logo"
            style={{
              height: "32px",
              marginBottom: "10px",
              marginTop: "20px",
              minWidth: "150px",
            }}
          />
        ) : (
          <img
            src={process.env.PUBLIC_URL + "/images/grayicon.png"}
            alt="RightWork icon"
            style={{
              height: "36px",
              marginBottom: "10px",
              marginTop: "20px",
            }}
          />
        )}
      </Box>
      <List>{sidebarItems.map(renderSidebarItem)}</List>
    </Drawer>
  );
}
