import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import Tooltip from "@mui/material/Tooltip";
import ButtonFilled from "./shared/helpers/ButtonFilled";
import SmartphoneOutlinedIcon from "@mui/icons-material/SmartphoneOutlined";
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";

function NotificationSettingsModal(props) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    border: "2px solid #E2E8F0",
    borderRadius: "15px",
    boxShadow: 24,
  };


  const [data, setData] = React.useState(props.notificationSettings);

  React.useEffect(() => {
    setData(props.notificationSettings);
  }, [props.notificationSettings]);

  const [managerDropdownOpen, setManagerDropdownOpen] = React.useState(true);
  const [employeeDropdownOpen, setEmployeeDropdownOpen] = React.useState(true);

  const toggleManagerDropdown = () => {
    setManagerDropdownOpen(!managerDropdownOpen);
  };

  const toggleEmployeeDropdown = () => {
    setEmployeeDropdownOpen(!employeeDropdownOpen);
  };

  const managerNotificationFields = [
    ["title", "REQUEST NOTIFICATIONS"],
    ["availability_change_requested", "Availability Change Requested"],
    ["time_off_requested", "Time Off Requested"],
    ["shift_pickup_requested", "Shift Pickup Requested"],
    ["shift_drop_requested", "Shift Drop Requested"],
    ["shift_trade_requested", "Shift Trade Requested"],
    ["title", "REMINDERS"],
    ["before_shift_1_hour", "1-Hour Until Shift"],
    ["before_shift_15_min", "15-Min Until Shift"],
    ["shift_available_for_pickup", "Shift Available For Pickup"],
    ["schedule_published", "Schedule Published"],
    // ... (props.chatEnabled ? [["title", "CHAT"], ["chat_new_message", "Chat New Message"]] : [])
  ]

  const employeeNotificationFields = [
    ["title", "REQUEST STATUS NOTIFICATIONS"],
    ["availability_request_status_change", "Availability Request Status Change"],
    ["time_off_request_status_change", "Time Off Request Status Change"],
    ["shift_pickup_request_status_change", "Shift Pickup Request Status Change"],
    ["shift_drop_request_status_change", "Shift Drop Request Status Change"],
    ["shift_trade_request_status_change", "Shift Trade Request Status Change"],
    ["title", "REMINDERS"],
    ["trade_offered", "Trade Offer Received"],
    ["before_shift_1_hour", "1-Hour Until Shift"],
    ["before_shift_15_min", "15-Min Until Shift"],
    ["shift_available_for_pickup", "Shift Available For Pickup"],
    ["schedule_published", "Schedule Published"],
    // ... (props.chatEnabled ? [["title", "CHAT"], ["chat_new_message", "Chat New Message"]] : [])
  ]

  function toggleCheckmark(itemKey, type) {
    const newData = { ...data };

    // Toggle the boolean value based on itemKey and type
    newData[itemKey][type] = !newData[itemKey][type];

    // Update the state with the modified data
    setData(newData);
  }

  function handleSubmit() {
    props.update_notification_settings(data);
    props.handleClose();
  }

  console.log("NOTIFICATION SETTINGS", data);

  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div
          className="absolute right-4 top-4 text-slate-500 cursor-pointer"
          onClick={props.handleClose}
        >
          <CloseIcon />
        </div>
        {data ? (
          <div className="w-full h-full pt-4">
            <div className="w-full h-full flex flex-col items-center">
              <div className="w-full px-5 pt-2">
                <h2 className="text-2xl">Notification Settings</h2>
              </div>
              <div className="w-full mt-4">
                <div>
                  <div className="w-full flex flex-row px-5 border-b border-slate-200">
                    <div className="w-1/2 h-full"></div>
                    <div className="w-1/2 h-full">
                      <div className="w-full flex flex-row mt-2 pb-2">
                        <div className="w-1/3 items-center text-center">
                          <div className="text-slate-500">
                            <SmartphoneOutlinedIcon />
                          </div>
                          <div className="text-slate-500 mt-2">App</div>
                        </div>
                        <div className="w-1/3 items-center text-center">
                          <div className="text-slate-500">
                            <NotificationsActiveOutlinedIcon />
                          </div>
                          <div className="text-slate-500 mt-2">Push</div>
                        </div>
                        <div className="w-1/3 items-center text-center">
                          <div className="text-slate-500">
                            <EmailOutlinedIcon />
                          </div>
                          <div className="text-slate-500 mt-2">Email</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ maxHeight: 480, overflowY: "auto" }}>
                  {props.permissionRole === "company_employee" ? (
                    <div>
                      <>
                        {employeeNotificationFields.map(
                          ([item, display], index) => (
                            <div key={index}>
                              {item === "title" ? (
                                <div className="w-full px-5 bg-slate-50 py-4 border-b border-slate-200 text-slate-500">
                                  {display}
                                </div>
                              ) : (
                                <div
                                  className={`flex flex-row items-center py-4 px-5 border-b border-slate-200`}
                                  key={index}
                                >
                                  <div className="flex flex-row items-center w-1/2 pr-10 h-12">
                                    <span className="text-base text-slate-900">
                                      {display}
                                    </span>
                                  </div>
                                  <div className="flex flex-row flex-1">
                                    <div className="w-1/3 flex items-center justify-center">
                                      <button
                                        onClick={() =>
                                          toggleCheckmark(item, "drawer")
                                        }
                                        className={`w-7 h-7 rounded-full ${
                                          data[item]?.drawer
                                            ? "bg-emerald-600"
                                            : "bg-slate-50 border border-slate-300"
                                        } flex items-center justify-center`}
                                      >
                                        {data[item]?.drawer && (
                                          <CheckIcon
                                            style={{
                                              color: "white",
                                              fontSize: "18px",
                                            }}
                                          />
                                        )}
                                      </button>
                                    </div>
                                    <div className="w-1/3 flex items-center justify-center">
                                      <button
                                        onClick={() =>
                                          toggleCheckmark(
                                            item,
                                            "push_notification"
                                          )
                                        }
                                        className={`w-7 h-7 rounded-full ${
                                          data[item]?.push_notification
                                            ? "bg-emerald-600"
                                            : "bg-slate-50 border border-slate-300"
                                        } flex items-center justify-center`}
                                      >
                                        {data[item]?.push_notification && (
                                          <CheckIcon
                                            style={{
                                              color: "white",
                                              fontSize: "18px",
                                            }}
                                          />
                                        )}
                                      </button>
                                    </div>
                                    <div className="w-1/3 flex items-center justify-center">
                                      <button
                                        onClick={() =>
                                          toggleCheckmark(item, "email")
                                        }
                                        className={`w-7 h-7 rounded-full ${
                                          data[item]?.email
                                            ? "bg-emerald-600"
                                            : "bg-slate-50 border border-slate-300"
                                        } flex items-center justify-center`}
                                      >
                                        {data[item]?.email && (
                                          <CheckIcon
                                            style={{
                                              color: "white",
                                              fontSize: "18px",
                                            }}
                                          />
                                        )}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          )
                        )}
                      </>
                    </div>
                  ) : (
                    <div>
                      <>
                        {managerNotificationFields.map(
                          ([item, display], index) => (
                            <div>
                              {item === "title" ? (
                                <div className="w-full px-5 bg-slate-50 py-4 border-b border-slate-200 text-slate-500">
                                  {display}
                                </div>
                              ) : (
                                <div
                                  className={`flex flex-row items-center py-4 px-5 border-b border-slate-200`}
                                  key={index}
                                >
                                  <div className="flex flex-row items-center w-1/2 pr-10 h-12">
                                    <span className="text-base text-slate-900">
                                      {display}
                                    </span>
                                  </div>
                                  <div className="flex flex-row flex-1">
                                    <div className="w-1/3 flex items-center justify-center">
                                      <button
                                        onClick={() =>
                                          toggleCheckmark(item, "drawer")
                                        }
                                        className={`w-7 h-7 rounded-full ${
                                          data[item]?.drawer
                                            ? "bg-emerald-600"
                                            : "bg-slate-50 border border-slate-300"
                                        } flex items-center justify-center`}
                                      >
                                        {data[item]?.drawer && (
                                          <CheckIcon
                                            style={{
                                              color: "white",
                                              fontSize: "18px",
                                            }}
                                          />
                                        )}
                                      </button>
                                    </div>
                                    <div className="w-1/3 flex items-center justify-center">
                                      <button
                                        onClick={() =>
                                          toggleCheckmark(
                                            item,
                                            "push_notification"
                                          )
                                        }
                                        className={`w-7 h-7 rounded-full ${
                                          data[item]?.push_notification
                                            ? "bg-emerald-600"
                                            : "bg-slate-50 border border-slate-300"
                                        } flex items-center justify-center`}
                                      >
                                        {data[item]?.push_notification && (
                                          <CheckIcon
                                            style={{
                                              color: "white",
                                              fontSize: "18px",
                                            }}
                                          />
                                        )}
                                      </button>
                                    </div>
                                    <div className="w-1/3 flex items-center justify-center">
                                      <button
                                        onClick={() =>
                                          toggleCheckmark(item, "email")
                                        }
                                        className={`w-7 h-7 rounded-full ${
                                          data[item]?.email
                                            ? "bg-emerald-600"
                                            : "bg-slate-50 border border-slate-300"
                                        } flex items-center justify-center`}
                                      >
                                        {data[item]?.email && (
                                          <CheckIcon
                                            style={{
                                              color: "white",
                                              fontSize: "18px",
                                            }}
                                          />
                                        )}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          )
                        )}
                      </>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div></div>
        )}
        <div className="w-full h-[90px] px-4 flex items-center justify-center border-t border-slate-300">
          <ButtonFilled text="Save Settings" onClick={() => handleSubmit()} />
        </div>
      </Box>
    </Modal>
  );
}

export default NotificationSettingsModal;
