import React from 'react'
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Tooltip from "@mui/material/Tooltip";
import AddIcon from "@mui/icons-material/Add";
import DailyPlanFormBuilder from './DailyPlanFormBuilder';
import { API_get_plan_tasks } from '../../../../constants';
import { getRightworkAPI } from '../../requests/utilities/requests';
import { get_t_str } from '../../../../helpers/helpers';
import { createDayArray } from '../../../../helpers/helpers';

function DailyPlanTaskConfiguration(props) {

  const [openFormBuilder, setOpenFormBuilder] =
    React.useState(false);

  const handleOpenFormBuilder = () => {
    setOpenFormBuilder(true);
  };

  const handleCloseFormBuilder = () => {
    setOpenFormBuilder(false);
  }

  const default_data = {
    name: "",
    description: "",
    subtasks: [],
    uuid: null,
    lower_bound_qhnum: null,
    upper_bound_qhnum: null,
    cron_days_of_the_week: [true, true, true, true, true, true, true],
  };

  const [modalData, setModalData] = React.useState(default_data);
  const [modalEdit, setModalEdit] = React.useState(false);

  // const forms = [
  //   {
  //     name: "Kizuki FOH Daily Report",
  //     description: "Required to be completed by FOH member every day",
  //     subtasks: [1,2,3,4,5],
  //     week_days: [true, true, true, true, true, true, true],
  //     start_time: 8,
  //     end_time: 16
  //   },
  //   {
  //     name: "Kizuki BOH Sanitation and Quality Check",
  //     description: "Required to be completed by BOH during opening",
  //     subtasks: [1,2,3],
  //     week_days: [true, true, true, true, true, true, true],
  //     start_time: 10,
  //     end_time: 18
  //   }
  // ]

  const day_abbrevs = createDayArray(0);

  function getSelectedDays(daysArray, selectionArray) {
    const selectedDays = [];
    for (let i = 0; i < daysArray.length; i++) {
      if (selectionArray[i]) {
        selectedDays.push(daysArray[i]);
      }
    }
    return selectedDays;
  }

  console.log("PLAN TASKS", props.planTasks)

  return (
    <>
      {!openFormBuilder ?
        <div>
          <div className="w-full flex items-center justify-between px-6 mt-16">
            <div>
              <h1 className="text-3xl text-slate-900">Task List</h1>
            </div>
            <Tooltip title="Add task">
              <button
                className="bg-violet-700 hover:bg-violet-800 text-white h-10 w-10 rounded-full"
                onClick={() => {
                  setModalData(default_data);
                  setModalEdit(false);
                  handleOpenFormBuilder();
                }}
              >
                <AddIcon style={{ fontSize: "22px", marginBottom: "2px" }} />
              </button>
            </Tooltip>
          </div>
          <div className="mt-8 flex flex-col">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 min-w-full">
              <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                <div className="overflow-hidden">
                  <table className="min-w-full divide-y divide-slate-200">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          className="w-[24%] pl-6 pr-3 py-3.5 text-left text-xs text-slate-500 font-medium"
                        >
                          Name
                        </th>
                        <th
                          scope="col"
                          className="flex-1 px-3 py-3.5 text-left text-xs text-slate-500 font-medium"
                        >
                          Description
                        </th>
                        <th
                          scope="col"
                          className="w-[16%] px-3 py-3.5 text-left text-xs text-slate-500 font-medium"
                        >
                          # Subtasks
                        </th>
                        <th
                          scope="col"
                          className="w-[16%] px-3 py-3.5 text-left text-xs text-slate-500 font-medium"
                        >
                          When
                        </th>
                        <th scope="col" className="relative py-3.5 pl-3 pr-6">
                          {/* <span className="sr-only">Edit</span> */}
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-slate-200 bg-white">
                      {props.planTasks?.map((task, index) => {
                        return (
                          <tr
                            key={index}
                            className={`${"hover:bg-slate-50 cursor-pointer"}`}
                            onClick={() => {
                              setModalData(task);
                              setModalEdit(true);
                              handleOpenFormBuilder();
                            }}
                          >
                            <td className="pl-6 pr-3 py-5 text-sm text-slate-900">
                              <div>
                                <p className="text-slate-900 text-sm">
                                  {task?.name}
                                </p>
                              </div>
                            </td>
                            <td className="whitespace-nowrap px-3 py-5 text-sm text-slate-900">
                              <div className="flex space-x-1">
                                {task?.description}
                              </div>
                            </td>
                            <td className="whitespace-nowrap px-3 py-5 text-sm text-slate-900">
                              <div>
                                <p className="text-slate-900 text-sm">
                                  {task?.subtasks?.length}
                                </p>
                              </div>
                            </td>
                            <td className="whitespace-nowrap px-3 py-5 text-sm text-slate-900">
                              <div>
                                <p className="text-slate-900 text-sm">
                                  {get_t_str(task?.lower_bound_qhnum)} - {get_t_str(task?.upper_bound_qhnum)}
                                </p>
                                {task?.cron_days_of_the_week?.includes(false) ? (
                                  <div className="flex space-x-0.5 mt-0.5">
                                    {getSelectedDays(day_abbrevs, task?.cron_days_of_the_week).map((day, index) => (
                                      <p className="text-slate-500 text-xs mt-0.5">
                                        {index <
                                        getSelectedDays(day_abbrevs, task?.cron_days_of_the_week)?.length -
                                          1
                                          ? `${day},`
                                          : `${day}`}
                                      </p>
                                    ))}
                                  </div>
                                ) : (
                                  <p className="text-slate-500 text-xs mt-0.5">
                                    Every day
                                  </p>
                                )}
                              </div>
                            </td>
                            <td className="relative whitespace-nowrap py-5 pl-3 pr-6 text-right text-sm font-medium w-5">
                              <div className="flex items-center justify-end w-fit h-full text-slate-500">
                                <div className="w-8">
                                  <ChevronRightIcon />
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <div className="border-b border-slate-200"></div>
                </div>
              </div>
            </div>
          </div>
        </div> :
        <div className="w-full px-6 mt-10">
          <div className="mt-8">
            <DailyPlanFormBuilder
              modalData={modalData}
              setModalData={setModalData}
              modalEdit={modalEdit}
              handleCloseFormBuilder={handleCloseFormBuilder}
              post_plan_task={props.post_plan_task}
              delete_plan_task={props.delete_plan_task}
            />
          </div>
        </div>
      }
    </>
  )
}

export default DailyPlanTaskConfiguration
