import React, { useState } from 'react';
import TextField from "@mui/material/TextField";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import AddIcon from '@mui/icons-material/Add';
import { generateTimeOptionsDF, PurpleSwitch } from '../../../../helpers/helpers';
import { ButtonGroup, Button, createTheme, ThemeProvider } from '@mui/material';
import { createDayArray } from '../../../../helpers/helpers';
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import SubtaskDrawer from './SubtaskDrawer';
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import InfoIcon from "@mui/icons-material/Info";

function DailyPlanFormBuilder(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const default_subtask_data = {
    uuid: null,
    name: "",
    description: "",
    type: "text",
    is_image_required: false
  };

  const [subtaskData, setSubtaskData] = React.useState(default_subtask_data);
  const [subtaskEdit, setSubtaskEdit] = React.useState(false);
  const [editingSubtaskIndex, setEditingSubtaskIndex] = useState(-1);

  const handleSaveSubtask = (newSubtaskData) => {
    if (subtaskEdit) {
      // Edit existing subtask
      const updatedSubtasks = [...props.modalData?.subtasks];
      updatedSubtasks[editingSubtaskIndex] = newSubtaskData;
      props.setModalData(prevData => ({
        ...prevData,
        subtasks: updatedSubtasks
      }));
    } else {
      // Add new subtask
      props.setModalData(prevData => ({
        ...prevData,
        subtasks: [...prevData?.subtasks, newSubtaskData]
      }));
    }
    handleClose();
  };

  const handleEditSubtask = (index) => {
    setSubtaskEdit(true);
    setSubtaskData(props.modalData?.subtasks[index]);
    setEditingSubtaskIndex(index);
    handleOpen();
  };

  const handleDeleteSubtask = (index) => {
    props.setModalData(prevData => ({
      ...prevData,
      subtasks: prevData?.subtasks?.filter((_, i) => i !== index)
    }));
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const reorderedSubtasks = Array.from(props.modalData.subtasks);
    const [reorderedItem] = reorderedSubtasks.splice(result.source.index, 1);
    reorderedSubtasks.splice(result.destination.index, 0, reorderedItem);

    props.setModalData(prevData => ({
      ...prevData,
      subtasks: reorderedSubtasks
    }));
  };

  const [daySelectionChecked, setDaySelectionChecked] = useState(false);

  const day_abbrevs = createDayArray(0);

  const theme = createTheme({
    status: {
      danger: "#e53e3e",
    },
    palette: {
      primary: {
        main: "#0971f1",
        darker: "#053e85",
      },
      neutral: {
        main: "#64748B",
        contrastText: "#fff",
      },
    },
  });

  const handleDaySelectionChecked = (event) => {
    const isChecked = event.target.checked;
    setDaySelectionChecked(isChecked);

    if (!isChecked) {
      // Set all days to true when the switch is turned off
      props.setModalData(prevData => ({
        ...prevData,
        cron_days_of_the_week: prevData?.cron_days_of_the_week.map(() => true)
      }));
    }
  };

  const handleAppliedToChange = (day_i) => {
    props.setModalData(prevData => ({
      ...prevData,
      cron_days_of_the_week: prevData?.cron_days_of_the_week.map((day, index) =>
        index === day_i ? !day : day
      )
    }));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    props.setModalData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleTimeChange = (event) => {
    const { name, value } = event.target;
    props.setModalData(prevData => ({
      ...prevData,
      [name === 'start_time' ? 'lower_bound_qhnum' : 'upper_bound_qhnum']: value
    }));
  };

  const appliedToButtonGroup = () => {
    return (
      <div className="w-full mt-2 bg-slate-50 rounded-lg flex">
        <ThemeProvider theme={theme}>
          <ButtonGroup
            style={{
              flex: 1,
              border: "1px solid #f1f5f9",
              borderRadius: "7px",
            }}
          >
            {props.modalData.cron_days_of_the_week?.map((d, i) => (
              <Button
                fullWidth
                color="neutral"
                size="small"
                style={{
                  border: d ? "1px solid #6d28d9" : "1px solid #f8fafc",
                  borderRadius: "7px",
                  backgroundColor: d ? "#ede9fe" : "transparent",
                  padding: "6px",
                  margin: "0 0.5px",
                }}
                key={i}
                onClick={() => handleAppliedToChange(i)}
              >
                {day_abbrevs[i]}
              </Button>
            ))}
          </ButtonGroup>
        </ThemeProvider>
      </div>
    );
  };

  console.log("MODAL DATA", props.modalData)

  // plan_task_uuid,
  //   name,
  //   description,
  //   lower_bound_qhnum,
  //   upper_bound_qhnum,
  //   cron_days_of_the_week_arr,
  //   subtasks_json

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="pb-10">
        <div className="w-full flex flex-col items-center">
          <div className="w-[65%] flex items-center justify-between">
            <div
              className="text-slate-500 hover:text-slate-600 text-sm flex items-center cursor-pointer"
              onClick={() => props.handleCloseFormBuilder()}
            >
              <div>
                <ChevronLeftIcon style={{ fontSize: "18px", marginBottom: "1px" }} />
              </div>
              <p>Back to Task List</p>
            </div>
            <div className="flex items-center space-x-2">
              {props.modalEdit ?
                <div
                  className="px-4 py-1.5 bg-rose-200 hover:bg-rose-300 text-rose-800 rounded cursor-pointer"
                  onClick={() => {
                    props.delete_plan_task(props.modalData?.uuid)
                    props.handleCloseFormBuilder()
                  }}
                >
                  Delete
                </div> :
                <div
                  className="px-4 py-1.5 bg-white hover:bg-slate-50 border border-slate-300 text-violet-700 rounded cursor-pointer"
                  onClick={() => {
                    props.handleCloseFormBuilder()
                  }}
                >
                  Cancel
                </div>
              }
              <div
                className="px-4 py-1.5 bg-violet-700 hover:bg-violet-800 text-white rounded cursor-pointer"
                onClick={() => {
                  props.post_plan_task(
                    props.modalData?.uuid ? props.modalData?.uuid : null,
                    props.modalData?.name,
                    props.modalData?.description,
                    props.modalData?.lower_bound_qhnum,
                    props.modalData?.upper_bound_qhnum,
                    props.modalData?.cron_days_of_the_week,
                    props.modalData?.subtasks
                  )
                  props.handleCloseFormBuilder()
                }}
              >
                Save
              </div>
            </div>
          </div>
          <div className="p-6 border border-slate-200 rounded-xl w-[65%] mt-4">
            <div>
              <p className="text-sm text-slate-500">Name</p>
              <div className="mt-1">
                <TextField
                  placeholder="FOH Opening Subtask List"
                  fullWidth
                  variant="outlined"
                  size="small"
                  name="name"
                  value={props.modalData?.name}
                  onChange={handleInputChange}
                  InputProps={{
                    sx: {
                      backgroundColor: "white",
                      borderRadius: "8px",
                      padding: "5px",
                      height: "45px",
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#6d28d9",
                      },
                    },
                  }}
                />
              </div>
            </div>
            <div className="mt-5">
              <p className="text-sm text-slate-500">Description</p>
              <div className="mt-1">
                <TextField
                  placeholder="Please complete this subtask list before the store opens"
                  fullWidth
                  variant="outlined"
                  size="small"
                  name="description"
                  value={props.modalData?.description}
                  onChange={handleInputChange}
                  InputProps={{
                    sx: {
                      backgroundColor: "white",
                      borderRadius: "8px",
                      padding: "5px",
                      height: "45px",
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#6d28d9",
                      },
                    },
                  }}
                />
              </div>
            </div>
            <div className="mt-5">
              <div className="flex items-center space-x-4">
                <p className="text-sm text-slate-500">Subtasks</p>
                <div
                  className="w-6 h-6 rounded-full bg-violet-700 hover:bg-violet-800 text-white flex items-center justify-center cursor-pointer"
                  onClick={() => {
                    setSubtaskEdit(false)
                    setSubtaskData(default_subtask_data)
                    handleOpen()
                  }}
                >
                  <AddIcon style={{ fontSize: "18px" }} />
                </div>
              </div>
              {props.modalData?.subtasks?.length > 0 ?
                <div className="mt-3">
                  <Droppable droppableId="subtasks">
                    {(provided) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        className="w-full border border-[#bdbdbd] rounded-xl overflow-hidden"
                      >
                        {props.modalData?.subtasks?.map((subtask, index) => (
                          <Draggable key={index} draggableId={`subtask-${index}`} index={index}>
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                className={`py-4 px-4 flex items-center justify-between bg-white ${index !== props.modalData?.subtasks?.length - 1 && !snapshot.isDragging ? 'border-b border-[#bdbdbd]' : ''} ${snapshot.isDragging ? 'bg-slate-100 rounded' : ''}`}
                              >
                                <div className="flex items-center gap-x-4 w-[45%]">
                                  <div className="text-slate-500" {...provided.dragHandleProps}>
                                    <DragIndicatorIcon />
                                  </div>
                                  <div>
                                    <p className="text-slate-900 text-sm">{subtask?.name}</p>
                                    <p className="text-slate-500 text-xs">{subtask?.description}</p>
                                  </div>
                                </div>
                                <div className="w-32">
                                  <div className={`w-fit rounded-md px-2.5 py-1 text-sm ${subtask?.type === 'text' ? 'bg-orange-50 border border-orange-200 text-orange-600' : 'bg-green-50 border border-green-200 text-green-600'}`}>
                                    <p>{subtask?.type === "text" ? "Text" : "Check"}</p>
                                  </div>
                                </div>
                                {subtask?.is_image_required ?
                                  <div className="text-slate-700 text-xs flex items-center gap-x-1 w-40">
                                    <CameraAltIcon style={{ fontSize: "20px" }} />
                                    <p>Image required</p>
                                  </div> :
                                  <div className="w-40">
                                  </div>
                                }
                                <div className="flex items-center gap-x-2 text-slate-500">
                                  <div
                                    className="cursor-pointer"
                                    onClick={() => handleEditSubtask(index)}
                                  >
                                    <EditOutlinedIcon />
                                  </div>
                                  <div
                                    className="cursor-pointer"
                                    onClick={() => handleDeleteSubtask(index)}
                                  >
                                    <DeleteOutlinedIcon />
                                  </div>
                                </div>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </div> :
                <div className="mt-4 w-full flex flex-col items-center justify-center">
                  <div className="w-8 h-8 rounded-lg bg-slate-100 flex items-center justify-center text-slate-900">
                    <InfoIcon style={{ fontSize: "18px" }} />
                  </div>
                  <p className="mt-2 text-xs w-72 text-center">
                    No subtasks created yet
                  </p>
                </div>
              }
            </div>
            <div className="mt-8">
              <div>
                <div>
                  <div>
                    <p className="text-sm text-slate-900">Time selection</p>
                    <p className="text-xs text-slate-500 mt-1">
                      Select the time range in which you this form to be completed. Notifications will be sent out accordingly.
                    </p>
                  </div>
                  <div className="mt-3 flex items-center space-x-3">
                    <div>
                      <Select
                        fullWidth
                        value={props.modalData?.lower_bound_qhnum}
                        name="start_time"
                        onChange={handleTimeChange}
                        size="small"
                        sx={{
                          borderRadius: "8px",
                          paddingY: "1px",
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#6d28d9",
                            },
                          height: "38px",
                          backgroundColor: "white",
                          minWidth: "120px"
                        }}
                      >
                        {generateTimeOptionsDF(0).map((v) => (
                          <MenuItem key={v.value} value={v.value}>
                            {v.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                    <div className="text-slate-500">
                      <ArrowForwardIcon />
                    </div>
                    <div>
                      <Select
                        fullWidth
                        value={props.modalData?.upper_bound_qhnum}
                        name="end_time"
                        onChange={handleTimeChange}
                        size="small"
                        sx={{
                          borderRadius: "8px",
                          paddingY: "1px",
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#6d28d9",
                            },
                          height: "38px",
                          backgroundColor: "white",
                          minWidth: "120px"
                        }}
                      >
                        {generateTimeOptionsDF(0).map((v) => (
                          <MenuItem key={v.value} value={v.value}>
                            {v.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-8">
              <div>
                <div className="flex items-center justify-between">
                  <div>
                    <p className="text-sm text-slate-900">Day selection</p>
                    <p className="text-xs text-slate-500 mt-1">
                      Is this form only to be assigned on certain days of the week?
                    </p>
                  </div>
                  <div>
                    <PurpleSwitch
                      checked={!props.modalData?.cron_days_of_the_week?.every(day => day)}
                      onChange={(event) => {
                        const newValue = event.target.checked;
                        props.setModalData(prevData => ({
                          ...prevData,
                          cron_days_of_the_week: prevData?.cron_days_of_the_week?.map(() => !newValue)
                        }));
                      }}
                    />
                  </div>
                </div>
                {!props.modalData?.cron_days_of_the_week?.every(day => day) && (
                  <div className="mt-4 max-w-[450px]">
                    <div>{appliedToButtonGroup()}</div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <SubtaskDrawer
        open={open}
        onClose={handleClose}
        subtaskData={subtaskData}
        setSubtaskData={setSubtaskData}
        subtaskEdit={subtaskEdit}
        default_modal_data={default_subtask_data}
        onSave={handleSaveSubtask}
      />
    </DragDropContext>
  );
}

export default DailyPlanFormBuilder;
