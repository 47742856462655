import React, { useState, useEffect } from "react";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import StackedBarChartIcon from "@mui/icons-material/StackedBarChart";
import StackedLineChartIcon from "@mui/icons-material/StackedLineChart";
import NoteAltOutlinedIcon from "@mui/icons-material/NoteAltOutlined";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DateCardSalesInfo from "../DateCardSalesInfo";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import Button from "@mui/material/Button";
import PersonCard from "../PersonCard";
import ShiftCard from "../ShiftCard";
import NewShiftCard from "../NewShiftCard";
import { DeploymentGraph } from "../visual_components/DeploymentGraph";
import { CoverageGraph } from "../visual_components/CoverageGraph";
import HoursOfOpsButton from "./HoursOfOpsButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import WeatherEventsCard from "../WeatherEventsCard";
import VisualCustomLineChart from "../VisualCustomLineChart";
import VisualChartDailyModal from "../VisualChartDailyModal";
import ResizableDailyShiftCard from "./ResizableDailyShiftCard";
import ResizableDailyBreakCard from "./ResizeableDailyBreakCard";
import {
  generateLaborSummary,
  get_t_str,
  PurpleSwitch,
} from "../../../../../helpers/helpers";
import DailyBreakCard from "./DailyBreakCard";

export default function DailySchedule(props) {
  const [isExpanded, setIsExpanded] = React.useState(false);
  const [employmentGuide, setEmploymentGuide] = React.useState(true);
  const [coverageGraph, setCoverageGraph] = React.useState(false);
  const [availabilityChecked, setAvailabilityChecked] = React.useState(true);
  const [preferenceChecked, setPreferenceChecked] = React.useState(true);
  const [hoo, setHoo] = React.useState([0, 24]);

  const handleAvailabilityChange = (event) => {
    setAvailabilityChecked(event.target.checked);
  };

  const handlePreferenceChange = (event) => {
    setPreferenceChecked(event.target.checked);
  };

  function handleExpandClick() {
    setIsExpanded((prevState) => !prevState);
  }

  function handleEmploymentGuideClick() {
    setEmploymentGuide(true);
    setCoverageGraph(false);
  }

  function handleCoverageGraphClick() {
    setCoverageGraph(true);
    setEmploymentGuide(false);
  }
  const [
    locked,
    date,
    date_str,
    date_dow,
    date_name,
    todays_date_yes,
    dow_name,
    day_shifts,
    day_request_off,
    dow_availability,
    edit_mode,
    day_in_past,
    forecasted_labor_day,
    forecasted_workload_day,
    recommended_schedule_labor_day,
    actual_schedule_labor_day,
    calculated_earned_labor_day,
    recommended_used_labor_vs_earned_day,
    actual_used_labor_vs_earned_day,
    adjustment_chosen,
    forecast_sales,
    actual_sales,
    dow_default_operating_hours,
    day_operating_hours,
    day_time_off_requests,
    historical_sales_context,
  ] = props.get_date_info(props.dowSelected);

  const daily_forecast_exclude_date =
    props.filter_df_to_day(props.forecast_exclude_dates, "date", date_str)
      .length > 0;

  const res = props.combine_data(
    forecasted_labor_day,
    forecasted_workload_day,
    recommended_schedule_labor_day,
    actual_schedule_labor_day
  );

  function grid_lines(hours) {
    // Ensure hours is a valid positive number
    if (hours < 0) return null;

    return [...Array(hours + 1).keys()].map((v, i) => {
      return (
        <div
          key={i} // Always include a key when rendering lists in React
          className="h-full border-l border-gray-200 absolute"
          style={{ left: `${hour_increment * i + 15}%` }}
        ></div>
      );
    });
  }

  const daily_weather = props.filter_df_to_day(props.weather, "date", date_str);
  const daily_events = props.filter_df_to_day(props.events, "date", date_str);

  useEffect(() => {
    if (dow_default_operating_hours) {
      const hoo =
        day_operating_hours.length > 0
          ? day_operating_hours[0]
          : dow_default_operating_hours[0];

      let hoo_open = hoo?.["open_time"];
      let hoo_close = hoo?.["close_time"];

      if (day_shifts.length > 0) {
        let minStart = day_shifts.reduce(
          (min, p) =>
            p.shift_start !== undefined && p.shift_start < min
              ? p.shift_start
              : min,
          day_shifts[0].shift_start
        );
        let maxEnd = day_shifts.reduce(
          (max, p) =>
            p.shift_end !== undefined && p.shift_end > max ? p.shift_end : max,
          day_shifts[0].shift_end
        );

        //const minShiftStart = person_day_shift
        hoo_open = Math.min(hoo_open, minStart);
        hoo_close = Math.max(hoo_close, maxEnd);
      }
      setHoo([hoo_open, hoo_close]);
    }
  }, [props.store_team_member_weekly_info_view]);

  const start_h = Math.floor(hoo[0]);
  const end_h = Math.ceil(hoo[1]);
  const hour_increment = 85 / (end_h - start_h);

  function format_hour(hour) {
    if (hour === 24) {
      return "12A";
    }
    if (hour === 12) {
      return "12P";
    }
    const h = hour % 24;
    return (h % 12) + (h > 12 ? "P" : "A");
  }

  const [data, setData] = useState({ summary: [], roleTypes: [] }); // Initialize data state

  const label1 = "Forecasted";
  const label2 = "Scheduled";

  useEffect(() => {
    let newData = generateLaborSummary(
      forecasted_labor_day,
      actual_schedule_labor_day,
      get_t_str,
      label1,
      label2
    );
    setData(newData); // Update data state
  }, [date_str, props.store_team_member_weekly_info_view]);

  const [openChart, setOpenChart] = React.useState(false);
  const handleOpenChart = () => setOpenChart(true);
  const handleCloseChart = () => setOpenChart(false);

  // Opening forecast adjusment modal
  const [openForecastAdjustment, setOpenForecastAdjustment] =
    React.useState(false);
  const handleOpenForecastAdjustment = () => setOpenForecastAdjustment(true);
  const handleCloseForecastAdjustment = () => setOpenForecastAdjustment(false);

  // Handle flagging day
  const [flagged, setFlagged] = React.useState(false);
  function toggleFlagged() {
    setFlagged((prevState) => !prevState);
  }

  // Opening notes modal
  const [openNotes, setOpenNotes] = React.useState(false);
  const handleOpenNotes = () => setOpenNotes(true);
  const handleCloseNotes = () => setOpenNotes(false);

  return (
    <div>
      <div className="w-full flex items-center">
        <div className="w-[15%] py-3 pl-2">
          <div className="flex items-center">
            <PurpleSwitch
              checked={availabilityChecked}
              onChange={handleAvailabilityChange}
              inputProps={{ "aria-label": "controlled" }}
              size="small"
            />
            <h4 className="text-xs">Show Unavailable</h4>
          </div>
          <div className="flex items-center mt-1">
            <PurpleSwitch
              checked={preferenceChecked}
              onChange={handlePreferenceChange}
              inputProps={{ "aria-label": "controlled" }}
              size="small"
            />
            <h4 className="text-xs">Show Preferences</h4>
          </div>
        </div>
        <div className="w-[85%] flex justify-between border-l border-gray-200">
          <div>
            <div className="ml-8 flex items-center space-x-5 pb-5">
              <DateCardSalesInfo
                view="daily"
                forecast_sales={forecast_sales}
                actual_sales={actual_sales}
                date_name={date_name}
                day_of_week_str={props.day_of_week_str}
                edit_mode={edit_mode}
                lock_day={props.lock_day}
                locked={locked}
                d={props.dowSelected}
                day_in_past={day_in_past}
                todays_date_yes={todays_date_yes}
                adjustment_chosen={adjustment_chosen}
                addForecastAdjustment={props.addForecastAdjustment}
                addAdjustmentFinished={props.addAdjustmentFinished}
                date_str={date_str}
                daily_weather={daily_weather}
                daily_events={daily_events}
                saveEvent={props.saveEvent}
                saveEventFinished={props.saveEventFinished}
                setSaveEventFinished={props.setSaveEventFinished}
                openForecastAdjustment={openForecastAdjustment}
                handleOpenForecastAdjustment={handleOpenForecastAdjustment}
                handleCloseForecastAdjustment={handleCloseForecastAdjustment}
                setOpenForecastAdjustment={setOpenForecastAdjustment}
                setOpenNotes={setOpenNotes}
                daily_forecast_exclude_date={daily_forecast_exclude_date}
                historical_sales_context={historical_sales_context}
              />
              <div>
                <HoursOfOpsButton
                  date_name={date_name}
                  //hoo={hoo}
                  //setHoo={setHoo}
                  hoo={
                    day_operating_hours.length > 0
                      ? day_operating_hours[0]
                      : dow_default_operating_hours[0]
                  }
                  get_t_str={props.get_t_str}
                  dow_name={dow_name}
                  edit_hoo={props.edit_hoo}
                  default_checked={
                    day_operating_hours.length > 0 ? false : true
                  }
                  start_qhnum={props.start_qhnum}
                  view="daily"
                />
              </div>
            </div>
          </div>
          <div>
            <div
              className="text-slate-500 hover:text-slate-900 flex items-center space-x-1 cursor-pointer"
              onClick={() => props.toggleDailyWeekly()}
            >
              <p className="text-xs">Weekly View</p>
              <div>
                <ArrowRightAltIcon />
              </div>
            </div>
            <div className="flex items-center justify-end space-x-2 mt-2">
              <div>
                <WeatherEventsCard
                  element="notes"
                  view="daily"
                  date_str={date_str}
                  date_name={date_name}
                  daily_weather={daily_weather}
                  daily_events={daily_events}
                  saveEvent={props.saveEvent}
                  deleteEvent={props.deleteEvent}
                  saveEventFinished={props.saveEventFinished}
                  setSaveEventFinished={props.setSaveEventFinished}
                  openNotes={openNotes}
                  handleOpenNotes={handleOpenNotes}
                  handleCloseNotes={handleCloseNotes}
                  setOpenNotes={setOpenNotes}
                />
              </div>
              <div className="text-slate-500">
                <IconButton
                  style={{ borderRadius: "10px" }}
                  onClick={handleOpenChart}
                >
                  {isExpanded ? (
                    <StackedLineChartIcon />
                  ) : (
                    <StackedLineChartIcon />
                  )}
                </IconButton>
              </div>
            </div>
          </div>
          {/* <Button
            onClick={() => props.toggleDailyWeekly()}
            variant="outlined"
            startIcon={<ArrowBackIcon />}
            sx={{ textTransform: "none", borderRadius: "8px" }}
          >
            Weekly View
          </Button> */}
        </div>
      </div>
      <div className="w-full relative border-b border-gray-200">
        {end_h !== undefined &&
          start_h !== undefined &&
          !(end_h === 0 && start_h === 0) &&
          grid_lines(end_h - start_h)}
        {props.store_team_member_weekly_info_view?.map((p, i) => {
          const [
            locked_person,
            person_day_shift,
            person_day_request_off,
            person_day_availability,
            person_day_time_off_requests,
          ] = props.get_person_shift_info(
            p,
            date_str,
            date_dow,
            day_shifts,
            day_request_off,
            dow_availability,
            day_time_off_requests
          );
          //shift_info={props.person_day_shift[0]}
          console.log("PERSON DAY AVAILABILITY", person_day_availability[0]);
          const splitAvailability = person_day_availability?.[0]?.available_to_2
            ? true
            : false;

          return (
            <div
              key={p.id}
              className="w-full border-t border-gray-200 py-2 flex items-center relative"
            >
              <div className="w-[15%] pl-2 pr-4">
                <PersonCard
                  key={p.id}
                  p={p}
                  role_names={props.role_names}
                  updatePersonInfo={props.updatePersonInfo}
                  lock_person={props.lock_person}
                  locked_person={locked_person}
                  id={p.id}
                  week_in_past={props.week_in_past}
                  role_types={props.role_types}
                  week_already_started={props.week_already_started}
                  view="daily"
                />
              </div>
              {person_day_shift.length > 0 ? (
                person_day_shift.map((shift, index) => {
                  let previousEndTime =
                    index > 0 ? person_day_shift[index - 1].shift_end : start_h;
                  const multipleShifts = person_day_shift.length > 1;
                  return (
                    <>
                      {!multipleShifts ? (
                        <ResizableDailyShiftCard
                          key={i}
                          weeky_or_daily="daily"
                          shift_info={shift}
                          shifts_info={person_day_shift}
                          multipleShifts={multipleShifts}
                          lockAllShifts={props.lockAllShifts}
                          p={p}
                          locked_mode={
                            !edit_mode | props.displayMode | locked_person ||
                            props.lockAllShifts
                          }
                          addShift={props.addShift}
                          get_t_str={props.get_t_str}
                          date_name={date_name}
                          availability_info={person_day_availability[0]}
                          role_names={props.role_names}
                          saveShiftFinished={props.saveShiftFinished}
                          setSaveShiftFinished={props.setSaveShiftFinished}
                          d={props.dowSelected}
                          setCopiedShift={props.setCopiedShift}
                          copiedShift={props.copiedShift}
                          time_off_conflict={
                            person_day_time_off_requests.length > 0
                          }
                          person_day_time_off_requests={
                            person_day_time_off_requests
                          }
                          role_types={props.role_types}
                          addMultipleShifts={props.addMultipleShifts}
                          businessWeekStartDayIndex={
                            props.businessWeekStartDayIndex
                          }
                          start_qhnum={props.start_qhnum}
                          frequentShifts={props.frequentShifts}
                          day_in_past={day_in_past}
                          post_missed_shift={props.post_missed_shift}
                          showUnpublishedShifts={props.showUnpublishedShifts}
                          defaultBreakLengthHours={
                            props.defaultBreakLengthHours
                          }
                          hour_increment={hour_increment}
                          previousEndTime={previousEndTime}
                        />
                      ) : (
                        <div
                          style={{
                            height: 32,
                            width: shift?.shift_length * hour_increment + "%",
                            marginLeft:
                              (shift?.shift_start - previousEndTime) *
                                hour_increment +
                              "%",
                            zIndex: 9,
                            position: "relative",
                            cursor: "default",
                          }}
                        >
                          <ShiftCard
                            key={i}
                            weeky_or_daily="daily"
                            shift_info={shift}
                            shifts_info={person_day_shift}
                            multipleShifts={multipleShifts}
                            lockAllShifts={props.lockAllShifts}
                            p={p}
                            locked_mode={
                              !edit_mode | props.displayMode | locked_person ||
                              props.lockAllShifts
                            }
                            addShift={props.addShift}
                            get_t_str={props.get_t_str}
                            date_name={date_name}
                            availability_info={person_day_availability[0]}
                            role_names={props.role_names}
                            saveShiftFinished={props.saveShiftFinished}
                            setSaveShiftFinished={props.setSaveShiftFinished}
                            d={props.dowSelected}
                            setCopiedShift={props.setCopiedShift}
                            copiedShift={props.copiedShift}
                            time_off_conflict={
                              person_day_time_off_requests.length > 0
                            }
                            person_day_time_off_requests={
                              person_day_time_off_requests
                            }
                            role_types={props.role_types}
                            addMultipleShifts={props.addMultipleShifts}
                            businessWeekStartDayIndex={
                              props.businessWeekStartDayIndex
                            }
                            start_qhnum={props.start_qhnum}
                            frequentShifts={props.frequentShifts}
                            day_in_past={day_in_past}
                            post_missed_shift={props.post_missed_shift}
                            showUnpublishedShifts={props.showUnpublishedShifts}
                            defaultBreakLengthHours={
                              props.defaultBreakLengthHours
                            }
                            hour_increment={hour_increment}
                            previousEndTime={previousEndTime}
                            editShiftsInPastEnabled={
                              props.editShiftsInPastEnabled
                            }
                          />
                        </div>
                      )}
                      {shift.break_length > 0 && multipleShifts && (
                        <>
                          <div
                            style={{
                              height: 32,
                              width: shift?.break_length * hour_increment + "%",
                              marginLeft:
                                (shift?.break_start - start_h) *
                                  hour_increment +
                                15 +
                                "%",
                              position: "absolute",
                              cursor: "default",
                              zIndex: 10,
                            }}
                          >
                            <DailyBreakCard />
                          </div>
                        </>
                      )}
                    </>
                  );
                })
              ) : !edit_mode | props.displayMode | locked_person ? (
                <div></div>
              ) : (
                <div
                  style={{
                    width: "85%",
                    marginLeft: "0%",
                    zIndex: 9,
                    height: 32,
                  }}
                >
                  <NewShiftCard
                    weekly_or_daily="daily"
                    p={p}
                    date_str={date_str}
                    addShift={props.addShift}
                    store_id={props.store_id}
                    get_t_str={props.get_t_str}
                    date_name={date_name}
                    availability_info={person_day_availability[0]}
                    role_names={props.role_names}
                    saveShiftFinished={props.saveShiftFinished}
                    setSaveShiftFinished={props.setSaveShiftFinished}
                    d={props.dowSelected}
                    copiedShift={props.copiedShift}
                    setCopiedShift={props.setCopiedShift}
                    addMultipleShifts={props.addMultipleShifts}
                    role_types={props.role_types}
                    businessWeekStartDayIndex={props.businessWeekStartDayIndex}
                    start_qhnum={props.start_qhnum}
                    frequentShifts={props.frequentShifts}
                    person_day_time_off_requests={person_day_time_off_requests}
                    defaultBreakLengthHours={props.defaultBreakLengthHours}
                  />
                </div>
              )}
              {person_day_time_off_requests &&
                person_day_time_off_requests[0] && (
                  <div
                    className={`bg-red-200 absolute h-10 rounded-lg flex items-center`}
                    style={{
                      width: "85%",
                      marginLeft: "15%",
                      zIndex: 5,
                    }}
                  >
                    <div className={`px-6 text-red-600 font-bold`}>
                      Time Off
                    </div>
                  </div>
                )}

              {preferenceChecked &&
                person_day_availability[0] &&
                person_day_availability[0].preferred_to > 0 && (
                  <div
                    className={`border-2 border-green-300 absolute h-10 rounded-lg`}
                    style={{
                      width:
                        (person_day_availability[0].preferred_to -
                          Math.max(
                            person_day_availability[0].preferred_from,
                            start_h
                          )) *
                          hour_increment +
                        "%",
                      marginLeft:
                        Math.max(
                          person_day_availability[0].preferred_from - start_h,
                          0
                        ) *
                          hour_increment +
                        15 +
                        "%",
                    }}
                  ></div>
                )}
              {availabilityChecked &&
                person_day_availability &&
                person_day_availability[0] && (
                  <div
                    className={`bg-slate-300  absolute h-10 rounded-lg`}
                    style={{
                      width:
                        Math.min(
                          (person_day_availability[0].available_from === 0 &&
                            person_day_availability[0].available_to === 24) ||
                            person_day_availability[0].available_from <= start_h
                            ? 0
                            : (person_day_availability[0].available_from -
                                start_h) *
                                hour_increment,
                          85
                        ) + "%",
                      marginLeft: 15 + "%",
                    }}
                  ></div>
                )}
              {availabilityChecked &&
                person_day_availability &&
                person_day_availability[0] && (
                  <div
                    className={`bg-slate-300 absolute h-10 rounded-lg`}
                    style={{
                      width: person_day_availability[0].available_from_2
                        ? Math.min(
                            (person_day_availability[0].available_from === 0 &&
                              person_day_availability[0].available_to === 24) ||
                              person_day_availability[0].available_to >= end_h
                              ? 0
                              : (person_day_availability[0].available_from_2 -
                                  person_day_availability[0].available_to) *
                                  hour_increment,
                            85
                          ) + "%"
                        : Math.min(
                            (person_day_availability[0].available_from === 0 &&
                              person_day_availability[0].available_to === 24) ||
                              person_day_availability[0].available_to >= end_h
                              ? 0
                              : (end_h -
                                  person_day_availability[0].available_to) *
                                  hour_increment,
                            85
                          ) + "%",
                      marginLeft:
                        Math.max(
                          (person_day_availability[0].available_to - start_h) *
                            hour_increment +
                            15,
                          15
                        ) + "%",
                    }}
                  ></div>
                )}
              {availabilityChecked &&
                person_day_availability &&
                person_day_availability[0] &&
                person_day_availability[0].available_to_2 && (
                  <div
                    className={`bg-slate-300 absolute h-10 rounded-lg`}
                    style={{
                      width:
                        Math.min(
                          (person_day_availability[0].available_from === 0 &&
                            person_day_availability[0].available_to === 24) ||
                            person_day_availability[0].available_to_2 >= end_h
                            ? 0
                            : (end_h -
                                person_day_availability[0].available_to_2) *
                                hour_increment,
                          85
                        ) + "%",
                      marginLeft:
                        Math.max(
                          (person_day_availability[0].available_to_2 -
                            start_h) *
                            hour_increment +
                            15,
                          15
                        ) + "%",
                    }}
                  ></div>
                )}
            </div>
          );
        })}
      </div>

      <div
        style={{ boxShadow: "0px -3px 3px rgb(20 20 20 / 10%" }}
        className="w-full bg-gray-100 bottom-0 left-0 sticky z-10 pb-4 shadow"
      >
        <div className="absolute -right-[2px] top-0 h-full bg-gray-100 w-[2px]"></div>
        <button
          className="absolute left-1 text-slate-500"
          onClick={handleExpandClick}
        >
          {isExpanded ? (
            <ExpandMoreIcon sx={{ fontSize: "28px" }} />
          ) : (
            <ExpandLessIcon sx={{ fontSize: "28px" }} />
          )}
        </button>
        <div className="w-full flex text-sm">
          <h5 className="w-[15%] text-right">
            {end_h !== undefined &&
            start_h !== undefined &&
            !(end_h === 0 && start_h === 0)
              ? `${start_h}A`
              : ""}
          </h5>
          {end_h !== undefined &&
            start_h !== undefined &&
            !(end_h === 0 && start_h === 0) &&
            [...Array(end_h - start_h).keys()].map((v, i) => {
              return (
                <h5
                  className="text-right"
                  style={{ width: hour_increment + "%" }}
                >
                  {format_hour(i + 1 + start_h)}
                </h5>
              );
            })}
        </div>
        {isExpanded && (
          <div className="flex mt-1.5">
            <div className="w-[15%] flex flex-col items-center">
              <div className="w-28 flex flex-col justify-center items-center">
                <div
                  className="w-24 h-12 mt-0.5 flex flex-col justify-center items-center rounded-xl shadow"
                  style={{
                    backgroundColor: props.calculate_color(
                      actual_schedule_labor_day,
                      forecasted_labor_day
                    ),
                  }}
                >
                  <h3 className="text-3xl text-white">
                    {props.calculate_letter(
                      actual_schedule_labor_day,
                      forecasted_labor_day
                    )}
                  </h3>
                </div>
              </div>
              <div className="mt-2">
                <div className="bg-white w-24 h-24 flex flex-col justify-center items-center rounded-xl shadow">
                  <h5 className="text-xs">Forecasted</h5>
                  <h5 className="text-xs">Hours</h5>
                  {/* <div><ComputerIcon /></div> */}
                  <div className="text-2xl font-bold mt-1">
                    {props.agg_column(forecasted_labor_day, "hours")}
                  </div>
                </div>
                <div className="bg-white w-24 h-24 mt-2 flex flex-col justify-center items-center rounded-xl shadow">
                  <h5 className="text-xs">Scheduled</h5>
                  <h5 className="text-xs">Hours</h5>
                  {/* <div><EventAvailableIcon /></div> */}
                  <div className="text-2xl font-bold mt-1">
                    {props.agg_column(actual_schedule_labor_day, "hours")}
                  </div>
                </div>
              </div>
            </div>
            {employmentGuide ? (
              <div className="w-[85%] h-64 relative">
                {/* <div className="absolute top-1 right-1 flex items-center">
                  <Tooltip title="Employment Guide">
                    <IconButton onClick={handleEmploymentGuideClick}>
                      <StackedBarChartIcon style={{ color: "#1e3a8a" }} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Workload Graph">
                    <IconButton onClick={handleCoverageGraphClick}>
                      <StackedLineChartIcon />
                    </IconButton>
                  </Tooltip>
                </div> */}
                {/* <DeploymentGraph
                  data={res.data}
                  roles={res.roles}
                  chartid="deploymentgraph"
                  hoo={hoo}
                  boxHeight={235}
                /> */}
                <div>
                  <VisualCustomLineChart
                    data={data.summary}
                    roleTypes={data.roleTypes}
                    date_name={props.date_name}
                    height={264}
                    isSmall={true}
                    label1={label1}
                    label2={label2}
                    start_h={start_h}
                    end_h={end_h}
                    hour_increment={hour_increment}
                  />
                </div>
              </div>
            ) : coverageGraph ? (
              <div className="w-[85%] h-64 relative px-4 py-2">
                <div className="absolute top-1 right-1 flex items-center">
                  <Tooltip title="Employment Guide">
                    <IconButton onClick={handleEmploymentGuideClick}>
                      <StackedBarChartIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Workload Graph">
                    <IconButton onClick={handleCoverageGraphClick}>
                      <StackedLineChartIcon style={{ color: "#1e3a8a" }} />
                    </IconButton>
                  </Tooltip>
                </div>
                <h4 className="texl-xl font-bold">Workload Graph</h4>
                <CoverageGraph
                  data={res.data}
                  chartid="coveragegraph"
                  hoo={hoo}
                  boxHeight={235}
                />
              </div>
            ) : (
              <div>Charts not rendering</div>
            )}
          </div>
        )}
      </div>
      <VisualChartDailyModal
        open={openChart}
        handleClose={handleCloseChart}
        data={data.summary}
        roleTypes={data.roleTypes}
        date_name={props.date_name}
        isSmall={false}
        label1={label1}
        label2={label2}
        gradeColor={props.calculate_color(
          actual_schedule_labor_day,
          forecasted_labor_day
        )}
        gradeLetter={props.calculate_letter(
          actual_schedule_labor_day,
          forecasted_labor_day
        )}
        forecastHours={props.agg_column(forecasted_labor_day, "hours")}
        scheduleHours={props.agg_column(actual_schedule_labor_day, "hours")}
      />
    </div>
  );
}
