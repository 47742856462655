import React from 'react'
import { get_t_str } from '../../../../helpers/helpers';
import ChecklistIcon from '@mui/icons-material/Checklist';
import Tooltip from '@mui/material/Tooltip';
import TaskNameCardEmployeeModal from './TaskNameCardEmployeeModal';
import { Lightbox } from "react-modal-image";
import TaskStatusBadge from './TaskStatusBadge';

function TaskNameCardEmployee(props) {

  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const [showLighbox, setShowLightbox] = React.useState(false)
  const [selectedImage, setSelectedImage] = React.useState(null)


  console.log("TASK", props.task)
  return (
    <>
      {showLighbox &&
        <div className="absolute w-screen h-screen z-[9999]">
          <Lightbox
            small={selectedImage}
            medium={selectedImage}
            // large={selectedImage}
            hideDownload={true}
            hideZoom={true}
            onClose={() => setShowLightbox(false)}
          />
        </div>
      }
      <div
        className={`p-3 border border-slate-200 hover:border-slate-900 rounded-lg bg-white min-w-[250px] cursor-pointer`}
        onClick={handleOpenModal}
      >
        <div className="flex justify-between gap-x-3">
          <div>
            <p className={`text-sm text-slate-900`}>{props.task?.plan_task?.name}</p>
            {props.task?.plan_subtask_assignments?.length  > 0 &&
              <Tooltip
                title={
                  <React.Fragment>
                    {props.task?.plan_subtask_assignments?.map((subtask) => (
                      <li key={subtask.id}>{subtask.plan_subtask?.name}</li>
                    ))}
                  </React.Fragment>
                }
              >
                <div className={`mt-1 flex items-center gap-x-1 text-xs w-fit text-slate-500`}>
                  <div>
                    <ChecklistIcon style={{ fontSize: "16px" }} />
                  </div>
                  <p>{props.task?.plan_subtask_assignments?.length } {props.task?.plan_subtask_assignments?.length  === 1 ? "subtask" : "subtasks"}</p>
                </div>
              </Tooltip>
            }
          </div>
          <div>
            <div>
              <p className="text-slate-900 text-xs text-right whitespace-nowrap">Complete Between</p>
            </div>
            <div className="flex space-x-1 mt-2">
              <p
                className={`text-xs text-slate-500`}
              >
                {get_t_str(props.task?.plan_task?.lower_bound_qhnum)}
              </p>
              <p className={`text-xs text-slate-500`}> - </p>
              <p
                className={`text-xs text-slate-500`}
              >
                {get_t_str(props.task?.plan_task?.upper_bound_qhnum)}
              </p>
            </div>
          </div>
        </div>
        <div className="mt-2">
          <TaskStatusBadge
            status={props.task?.status}
            submitted_at={props.task?.submitted_at_formatted}
          />
        </div>
      </div>
      <TaskNameCardEmployeeModal
        open={openModal}
        handleClose={handleCloseModal}
        task={props.task}
        save_plan_task_assignment={props.save_plan_task_assignment}
        homeCompany={props.homeCompany}
        setShowLightbox={setShowLightbox}
        setSelectedImage={setSelectedImage}
      />
    </>
  )
}

export default TaskNameCardEmployee
