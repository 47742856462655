import React from 'react'
import CheckIcon from "@mui/icons-material/Check";
import { get_t_str } from '../../../../helpers/helpers';
import axios from "axios";
import TextField from "@mui/material/TextField";
import { getDefaultAxiosConfig } from '../../requests/utilities/requests';
import {
  API_post_file_upload_with_eagerly_generated_presigned_url,
  API_get_file_upload
} from '../../../../constants';
import { getRightworkAPI } from '../../requests/utilities/requests';
import ImageIcon from '@mui/icons-material/Image';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import AttachFileIcon from '@mui/icons-material/AttachFile';

function TaskNameCardInput(props) {

  const [isFileLoading, setIsFileLoading] = React.useState(false);

  const handleTextChange = (event, subtaskIndex) => {
    const updatedSubtasks = [...props.modalData.plan_subtask_assignments];
    updatedSubtasks[subtaskIndex].value_text = event.target.value;
    props.setModalData({
      ...props.modalData,
      plan_subtask_assignments: updatedSubtasks
    });
  };

  const handleCheckChange = (subtaskIndex) => {
    const updatedSubtasks = [...props.modalData.plan_subtask_assignments];
    updatedSubtasks[subtaskIndex].value_boolean = !updatedSubtasks[subtaskIndex].value_boolean;
    props.setModalData({
      ...props.modalData,
      plan_subtask_assignments: updatedSubtasks
    });
  };

  const uploadFile = async (file, type) => {
    const formData = new FormData();
    formData.append("company_id", props.homeCompany);
    formData.append("file_name", file.name);
    formData.append("type", type);

    setIsFileLoading(true);

    try {
      // [1] Get presigned URL
      const axiosConfig = await getDefaultAxiosConfig();
      const res = await axios.post(API_post_file_upload_with_eagerly_generated_presigned_url, formData, {
        headers: {
          ...axiosConfig.headers,
          "Content-Type": "multipart/form-data",
        },
      });
      const fileUUID = res?.data?.redux?.payload?.uuid;
      const presignedUrl = res?.data?.redux?.payload?.presigned_url;

      // [2] Upload file
      await axios.put(presignedUrl, file, {
        headers: {
          'Content-Type': file.type,
        }
      });

      // [3] Get file URL
      let api_params = {
        file_upload_uuid: fileUUID,
      };
      const resFile = await getRightworkAPI(API_get_file_upload, api_params);

      setIsFileLoading(false);

      return {uuid: fileUUID, presigned_url: resFile?.data?.redux?.payload?.presigned_url};
    } catch (error) {
      console.error("Error uploading file:", error);
      setIsFileLoading(false);
      throw error;
    }
  };

  const handleFileChange = async (event, subtaskIndex) => {
    const file = event.target.files[0];
    if (file) {
      try {
        const fileObject = await uploadFile(file, "plan_subtask_assignment");

        const updatedSubtasks = [...props.modalData.plan_subtask_assignments];
        updatedSubtasks[subtaskIndex].file_upload = fileObject;
        props.setModalData({
          ...props.modalData,
          plan_subtask_assignments: updatedSubtasks
        });
      } catch (error) {
        // Handle error (e.g., show error message to user)
        console.error("Failed to upload file:", error);
      }
    }
  };

  const handleDeleteFile = (subtaskIndex) => {
    const updatedSubtasks = [...props.modalData.plan_subtask_assignments];
    updatedSubtasks[subtaskIndex].file_upload = null;
    props.setModalData({
      ...props.modalData,
      plan_subtask_assignments: updatedSubtasks
    });
  };

  console.log("SUBTASK", props.subtask)

  return (
    <>
      <div className="py-5">
        <div className="flex">
          <div className="text-sm leading-none w-5">{props.index + 1}.</div>
          {props.subtask.plan_subtask.type === "text" ?
            <div className="w-full">
              <div className="flex gap-x-3">
                <div>
                  <p className="text-sm text-slate-900 leading-none">{props.subtask.plan_subtask.name}</p>
                  <p className="text-xxs text-slate-500 mt-1">{props.subtask.plan_subtask.description}</p>
                </div>
              </div>
              <div className="mt-2">
                <TextField
                  placeholder="Enter text here"
                  fullWidth
                  variant="outlined"
                  size="small"
                  name="name"
                  value={props.subtask.value_text}
                  onChange={(e) => handleTextChange(e, props.index)}
                  InputProps={{
                    sx: {
                      backgroundColor: "white",
                      borderRadius: "8px",
                      padding: "5px",
                      height: "40px",
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#6d28d9",
                      },
                    },
                  }}
                />
              </div>
            </div> :
          props.subtask.plan_subtask.type === "check" ?
            <div className="flex gap-x-3">
              <div
                className={`border border-[#bdbdbd] hover:border-slate-800 w-6 h-6 rounded ${props.subtask.value_boolean ? "bg-violet-700" : "bg-white"} cursor-pointer flex items-center justify-center text-white`}
                onClick={() => handleCheckChange(props.index)}
              >
                {props.subtask.value_boolean ? <CheckIcon style={{ fontSize: "18px" }} /> : ""}
              </div>
              <div>
                <p className="text-sm text-slate-900 leading-none">{props.subtask.plan_subtask.name}</p>
                <p className="text-xxs text-slate-500 mt-1">{props.subtask.plan_subtask.description}</p>
              </div>
            </div> :
            <div></div>
          }
        </div>
        <div className="flex mt-2">
          <div className="w-5"></div>
          {!props.subtask?.file_upload?.uuid ?
            <div>
              <label htmlFor={`file-upload-${props.index}`} className="text-slate-500 cursor-pointer">
                <span>
                  {!isFileLoading ? (
                    <>
                      <AttachFileIcon />
                      {props.subtask.plan_subtask.is_image_required && (
                        <span className="text-slate-500 text-xxs">Required</span>
                      )}
                    </>
                  ) : (
                    <CircularProgress size="1rem" sx={{ color: "#6d28d9", marginTop: "4px" }} />
                  )}
                </span>
              </label>
              <input
                id={`file-upload-${props.index}`}
                name={`file-upload-${props.index}`}
                type="file"
                className="hidden"
                onChange={(e) => handleFileChange(e, props.index)}
                accept=".jpg, .jpeg, .png"
              />
            </div> :
            <div className="flex space-x-2">
              <div
                className="text-slate-500 hover:text-slate-500 cursor-pointer"
                onClick={() => handleDeleteFile(props.index)}
              >
                <CloseIcon style={{ fontSize: "18px", marginBottom: "2px" }} />
              </div>
              <div
                className="text-violet-700 hover:text-violet-900 text-sm cursor-pointer"
                onClick={() => {
                  props.setShowLightbox(true)
                  props.setSelectedImage(props.subtask?.file_upload?.presigned_url)
                }}
              >
                <span><ImageIcon style={{ fontSize: "18px" }} /></span> 1 Image Attached
              </div>
            </div>
          }
        </div>
      </div>
    </>
  )
}

export default TaskNameCardInput
