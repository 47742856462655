import React from 'react'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { get_t_str } from '../../../../helpers/helpers';
import TextField from "@mui/material/TextField";
import TaskNameCardInput from './TaskNameCardInput';
import TaskStatusBadge from './TaskStatusBadge';

function TaskNameCardEmployeeModal(props) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    maxHeight: 800,
    bgcolor: "background.paper",
    border: "2px solid #E2E8F0",
    borderRadius: "15px",
    boxShadow: 24,
    p: 4,
    overflowY: "auto",
  };

  const [modalData, setModalData] = React.useState(props.task)

  React.useEffect(() => {
    setModalData(props.task)
  }, [props.task])



  // [POST] api/plan_info/save_plan_task_assignment
  // * store_id (auth)
  // * plan_task_assignment_uuid
  // * plan_subtask_assignment
  //     JSON.stringify([
  //         {
  //             "uuid",  (PlanSubtaskAssignment)
  //             "value_text",
  //             "value_boolean",
  //             "file_upload_uuid",
  //         }
  //     ])
  const handleSave = (is_submit) => {

    props.save_plan_task_assignment(
      modalData.uuid,
      modalData.plan_subtask_assignments,
      is_submit
    )
    props.handleClose();
  };

  console.log("MODAL DATA", modalData)

  return (
    <>
      <Modal
        open={props.open}
        onClose={() => props.handleClose()}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={style}>
          <div
            className="absolute right-2 top-2 text-gray-500 cursor-pointer"
            onClick={() => props.handleClose()}
          >
            <CloseIcon />
          </div>
          <div>
            <div className="w-full flex items-end justify-between">
              <div>
                <div>
                  <p className="text-xs text-slate-500">Name</p>
                  <p className="text-base text-slate-900">{modalData?.plan_task?.name}</p>
                </div>
                <div className="mt-2">
                  <p className="text-xs text-slate-500">Description</p>
                  <p className="text-base text-slate-900">{modalData?.plan_task?.description}</p>
                </div>
                <div className="mt-2">
                  <p className="text-xs text-slate-500">Complete Between</p>
                  <p className="text-base text-slate-900">{get_t_str(modalData?.plan_task?.lower_bound_qhnum)} - {get_t_str(modalData?.plan_task?.upper_bound_qhnum)}</p>
                </div>
                <div className="mt-2">
                  <p className="text-xs text-slate-500">Status</p>
                  <div className="mt-1.5">
                    <TaskStatusBadge
                      status={modalData?.status}
                      submitted_at={modalData?.submitted_at_formatted}
                      size="large"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-4 bg-slate-50 rounded-lg px-5 py-2">
              {modalData?.plan_subtask_assignments?.map((subtask, index) => {
                return(
                  <div key={index}>
                    <TaskNameCardInput
                      subtask={subtask}
                      index={index}
                      modalData={modalData}
                      setModalData={setModalData}
                      homeCompany={props.homeCompany}
                      setShowLightbox={props.setShowLightbox}
                      setSelectedImage={props.setSelectedImage}
                    />
                  </div>
                )
              })}
            </div>
            <div className="w-full flex justify-end mt-8">
              <div className="flex space-x-3">
                <div className="px-8 py-2 bg-white border border-violet-700 text-violet-700 hover:bg-slate-100 rounded-full text-base w-fit cursor-pointer"
                  onClick={() => props.handleClose()}
                >
                  Cancel
                </div>
                <div className="px-8 py-2 bg-violet-700 hover:bg-violet-800 border text-white rounded-full text-base w-fit cursor-pointer"
                  onClick={() => handleSave(false)}
                >
                  Save
                </div>
                <div className="px-8 py-2 bg-emerald-600 hover:bg-emerald-700 border text-white rounded-full text-base w-fit cursor-pointer"
                  onClick={() => handleSave(true)}
                >
                  Submit Answers
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  )
}

export default TaskNameCardEmployeeModal
