import React, { useEffect } from "react";
import Drawer from "@mui/material/Drawer";
import DrawerHeader from "../../requests/shared/helpers/DrawerHeader";
import DrawerFooterContainerLarge from "../../requests/shared/helpers/DrawerFooterContainerLarge";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import ButtonOutlined from "../../requests/shared/helpers/ButtonOutlined";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import { formatName } from "../../../../helpers/helpers";


  function SubtaskDrawer(props) {

    const handleChange = (event) => {
      const { name, value } = event.target;
      props.setSubtaskData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    };

    const handleCheckboxChange = (event) => {
      const { name, checked } = event.target;
      props.setSubtaskData((prevData) => ({
        ...prevData,
        [name]: checked,
      }));
    };

    const handleSave = () => {
      props.onSave(props.subtaskData);
    };


  return (
    <Drawer anchor="right" open={props.open} onClose={props.onClose}>
      <div className="w-[450px] h-full bg-slate-50 relative">
        <div className="max-h-screen overflow-auto">
          <DrawerHeader
            title={props.subtaskEdit ? `Edit Subtask` : `Add Subtask`}
            onClose={props.onClose}
          />
          <div className="w-full px-6">
            <div className="mt-7">
              <p className="text-sm text-slate-500">Name</p>
              <div className="mt-1">
                <TextField
                  placeholder="Prep food"
                  fullWidth
                  variant="outlined"
                  size="small"
                  name="name"
                  value={props.subtaskData.name}
                  onChange={handleChange}
                  InputProps={{
                    sx: {
                      backgroundColor: "white",
                      borderRadius: "8px",
                      padding: "5px",
                      height: "45px",
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#6d28d9",
                      },
                    },
                  }}
                />
              </div>
            </div>
            <div className="mt-7">
              <p className="text-sm text-slate-500">Description</p>
              <div className="mt-1">
                <TextField
                  placeholder="Cut up fruits and put in fridge"
                  fullWidth
                  variant="outlined"
                  size="small"
                  name="description"
                  value={props.subtaskData.description}
                  onChange={handleChange}
                  InputProps={{
                    sx: {
                      backgroundColor: "white",
                      borderRadius: "8px",
                      padding: "5px",
                      height: "45px",
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#6d28d9",
                      },
                    },
                  }}
                />
              </div>
            </div>
            <div className="mt-7">
              <p className="text-slate-500 text-sm">Type</p>
              <div className="mt-1">
                <Select
                  fullWidth
                  value={props.subtaskData.type}
                  name="type"
                  onChange={handleChange}
                  size="small"
                  sx={{
                    backgroundColor: "white",
                    borderRadius: "8px",
                    padding: "5px",
                    height: "45px",
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#6d28d9",
                    },
                  }}
                >
                  {["text", "check"].map((option, index) => (
                    <MenuItem key={index} value={option}>
                      {formatName(option)}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </div>
            <div className="mt-7">
              <div className="flex items-center">
                <Checkbox
                  name="is_image_required"
                  checked={props.subtaskData.is_image_required}
                  onChange={handleCheckboxChange}
                  size="small"
                  sx={{
                    color: "#6d28d9",
                    "&.Mui-checked": {
                      color: "#6d28d9",
                    },
                  }}
                />
                <p className="text-sm text-slate-500 ml-1 py-2">Require image to submit</p>
              </div>
            </div>
          </div>
          <DrawerFooterContainerLarge>
            <div className="w-full">
              <LoadingButton
                fullWidth
                loadingPosition="end"
                endIcon={<SaveIcon />}
                variant="contained"
                sx={{
                  textTransform: "none",
                  borderRadius: "50px",
                  paddingY: "14px",
                  backgroundColor: "#6d28d9",
                  "&:hover": {
                    backgroundColor: "#5b21b6",
                  },
                  fontSize: "16px",
                }}
                onClick={handleSave}
              >
                Save
              </LoadingButton>
            </div>
            <div className="w-full mt-3">
              <ButtonOutlined text="Cancel" onClick={props.onClose} />
            </div>
          </DrawerFooterContainerLarge>
          <div className="h-[200px]"></div>
        </div>
      </div>
    </Drawer>
  );
}

export default SubtaskDrawer;
